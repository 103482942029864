import { useSelector } from "react-redux";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import SimpleBar from 'simplebar-react';
import { genericSKUBreakdown, getConditionNameBySymbol } from "../../../../common/helpers/product";
import { useConditionsQuery } from "../../../../services/condition";
import ProductTitle from "../../../../Components/Common/ProductTitle";
import { useState } from 'react';
import { DEFAULT_PER_PAGE } from '../../../../common/constants';
import { useParams } from "react-router-dom";
import BPagination from '../../../../Components/Common/BPagination';

import {
  useStocktakeItemsQuery,
  useLazyStocktakeItemsQuery,
  useStocktakeItemsCreateMutation,
  useStocktakeItemsUpdateMutation,
  useStocktakeItemsDeleteMutation
} from '../../../../services/stocktakeitems';

const ScannedItems = props => {
  const { data: conditions_data } = useConditionsQuery({ per_page: 1000 });
  const stocktake = useSelector(store => store.stocktake);
  const scanned_items = stocktake.scanned_items ?? {};
  const scanned_items_pagination = stocktake.scanned_items_pagination ?? {};
  const scanned_items_keys = Object.keys(scanned_items);

  const { id } = useParams();
  const [params, setParams] = useState({});
  const [search_param, setSearchParam] = useState('');
  const [search, setSearch] = useState(null);
  const { data, isFetching, reload } = useStocktakeItemsQuery({
    ...params,
    page: scanned_items_pagination.current_page ?? 1,
    per_page: scanned_items_pagination.per_page ?? DEFAULT_PER_PAGE,
    keywords: search_param,
    stock_take_id: id
  });

  const handleSearch = () => {
    setSearchParam(search);
  };

  return (
    <Row>
      <SimpleBar style={{ maxHeight: '500px' }}>
        <Col lg={12}>
          <div className="col-sm" style={{ padding: '10px' }}>
            <div className="d-flex justify-content-sm-end">
              <div className="search-box ms-2 col-md-3 ">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value || undefined);
                    }}
                    placeholder="Search by Title or Generic SKU"
                    value={search || ''}
                  />
                  <i className="ri-search-line search-icon"></i>
                </form>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2 ms-2">
                <Button
                  color="secondary"
                  outline
                  onClick={handleSearch}
                  className="custom-toggle active"
                >
                  <span className="icon-on">
                    <i className="ri-search-line search-icon align-bottom me-1"></i>
                    Search
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <table
            className="table align-middle table-nowrap table-striped-columns"
            id="table_stocktake"
          >
            <thead className="table-light bg-light sticky-top">
              <tr>
                <th scope="col" style={{ width: '23 %' }}>
                  Product
                </th>
                <th
                  scope="col"
                >
                  Size
                </th>
                <th scope="col">Condition</th>
                <th scope="col">Count</th>
                <th scope="col" style={{ display: 'none' }}></th>
              </tr>
            </thead>
            <tbody>
              {
                (scanned_items_keys || []).map(item => {
                  const gsku = genericSKUBreakdown(item);
                  return (
                    <tr key={item}>
                      <td>
                        <ProductTitle
                          generic_sku={item}
                          title={scanned_items[item].title}
                          style={{ maxWidth: '350px' }}
                        />
                      </td>
                      <td>{gsku.size}</td>
                      <td>{getConditionNameBySymbol(gsku.condition, conditions_data?.data)}</td>
                      <td>{scanned_items[item].quantity}</td>
                      <td className="text-center" style={{ display: 'none' }}>
                        <ButtonGroup size="sm">
                          <Button color="primary" className="btn-icon" outline><i className="ri-subtract-line" /></Button>
                          <Button color="primary" className="btn-icon" outline><i className="ri-add-line" /></Button>
                        </ButtonGroup>
                        <Button size="sm" color="danger" className="ms-1 btn-icon"><i className="ri-delete-bin-line" /></Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          {/* <BPagination
            data={data}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
          /> */}
        </Col>
      </SimpleBar>
    </Row>
  );
}

export default ScannedItems;