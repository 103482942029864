import { Col, Row } from "reactstrap";
import SimpleBar from 'simplebar-react';

const InventoryView = props => {
  return (
    <Row>
      <SimpleBar style={{ maxHeight: '500px' }}>
        <Col lg={12}>
          <table
            className="table align-middle table-nowrap table-striped-columns"
            id="table_stocktake"
          >
            <thead className="table-light bg-light sticky-top">
              <tr>
                <th scope="col" style={{ width: '23 %' }}>
                  Product
                </th>
                <th
                  scope="col"
                  style={{ width: '1%' }}
                  className="text-center"
                >
                  Quantity on Hand
                </th>
                <th scope="col">Variances</th>
                <th scope="col">Physical Count</th>
              </tr>
            </thead>
          </table>
        </Col>
      </SimpleBar>
    </Row>
  );
}

export default InventoryView;