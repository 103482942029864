import { useSelector } from "react-redux";
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import { genericSKUBreakdown, getConditionNameBySymbol } from "../../../../common/helpers/product";
import { useConditionsQuery } from "../../../../services/condition";

const ScanMode = props => {
  const { data: conditions_data } = useConditionsQuery({ per_page: 1000 });
  const { last_scanned } = useSelector(store => store.stocktake);
  const sku_details = genericSKUBreakdown(last_scanned.barcode);
  return (
    <CardBody >
      {/* <Row className="text-left"><h3>Scan Mode</h3></Row> */}
      <Row className="text-center">
        <Col lg={3}></Col>
        <Col lg={6}>
          <div className="search-box flex-nowrap product-search mb-3 position-relative ">
            <Input
              type="text"
              className="form-control bg-light border-0 w-100 scan-input"
              placeholder="Scan Product"
              aria-describedby="addon-wrapping"
              onKeyDown={props.handleKeyDown}
            />
            <i className="ri-qr-scan-line search-icon scan-icon"></i>
          </div>
        </Col>
        <Col lg={3}></Col>
      </Row>
      <div className={`scan-item-details ${last_scanned.existing ? '' : 'not-existing'}`}>
        <Row className="text-center">
          <Col><div className="pb-2">Title</div><strong>{last_scanned.title}</strong></Col>
          <Col lg={2}><div className="pb-2">Generic SKU</div><strong>{last_scanned.barcode}</strong></Col>
          <Col lg={2}><div className="pb-2">Size</div><strong>{sku_details.size}</strong></Col>
          <Col lg={2}><div className="pb-2">Condition</div><strong>{getConditionNameBySymbol(sku_details.condition, conditions_data?.data)}</strong></Col>
          <Col lg={2}><div className="pb-2">Count</div><strong>{last_scanned.quantity || 0}</strong></Col>
        </Row>
      </div>
    </CardBody>
  );
}

export default ScanMode;